$breakpoint: 750px;

* {
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

form,
p {
  padding: 0;
  margin: 0;
}

input,
a {
  width: 100%;
  height: 32px;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  font-size: 11px;
  line-height: 32px;
  margin-bottom: 16px;
  padding: 0 8px;
  color: #0a266d;
  outline: none;
}
input::placeholder,
a {
  color: #0a266d;
  opacity: 1;
}

a.continue {
  color: white;
  padding: 0;
  font-size: initial;
}

input[type="submit"] {
  cursor: pointer;
}
input[type="submit"]:hover,
a:hover {
  opacity: 0.75;
}
input.error {
  position: relative;
  border: 1px solid #b41f24;
}

hr {
  left: 0;
  width: 100%;
  z-index: 5;
  border-top: none;
  border-color: white;
}

form {
  width: 100%;
}

footer p {
  font-weight: bold;
}
.footer-left > span,
.footer-left > a {
  padding-right: 20px;
}

.footer-left > a {
  color: white;
}

#bg-video {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  background-position: center center;
  background-size: contain;
  object-fit: cover;
  z-index: 0;
}

#bg-image {
  position: absolute;
  bottom: 0;
  background-color: #101b35;
  background-position: center center;
  object-fit: cover;
  max-width: 100%;
  z-index: 3;
}

.app {
  display: block;
  flex-direction: column;
  color: white;
  background-color: #101b35;

  .content {
    flex: 1;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 4;
    overflow: scroll;
  }
}

.brand-logo {
}

.brand-caption.splash {
  max-width: 600px;
  margin: 0 auto;
  padding: 0px 15px;
  font-size: 18px;

  p {
    margin-bottom: 15px;
  }
}

.page-content {
  // min-height: calc(100vh - 60px);
  // padding-top: 150px;
}

.brand-caption {
  display: block;
  margin: auto 0;
  width: 100%;
  height: fit-content;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.2px;
}

.brand-message {
  width: 100%;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
}
.brand-message p {
  line-height: 50px;
}

.brand-footer {
  z-index: 4;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 44px;
  padding: 0 44px;
  font-size: 12px;
  bottom: 0;
}

.brand-form-footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  p {
    margin: 0 5px 15px;
  }
}
.brand-footer-error {
  position: absolute;
  bottom: 13px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  color: white;
  p {
    margin: 0 5px 15px;
  }
}

.form-fields {
  width: 300px;
  margin: 0 auto;
  padding-bottom: 105px;
}
.group-name-org {
  display: block;
}
.group-email-submit {
  display: flex;
  justify-content: space-between;
  .email {
    width: 207px;
    margin-bottom: 0;
  }
  .submit {
    width: 80px;
    background: none;
    margin-bottom: 0;
    border: 1px solid white;
    color: white;
  }
}
.alert {
  position: relative;
  top: -10px;
  background-color: #b41f24;
  border-radius: 4px;
  font-size: 9px;
  line-height: 14px;
  width: fit-content;
  height: 18px;
  padding: 2px 6px;
}
.alert.email {
  top: 6px;
}
.alert::after {
  position: absolute;
  content: "";
  border: 4px solid transparent;
}
.top.alert::after {
  /* UP TRIANGLE */
  border-bottom-color: #b41f24;
  border-top: 0;
  /* POSITION TAIL AT BOTTOM OF BOX */
  top: -4px;
  left: 50%;
  margin-left: -4px;
}

.alert::before {
  position: absolute;
  top: -28px;
  right: -170px;
  content: "x";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #b41f24;
  z-index: 30;
  line-height: 13px;
  font-size: 11px;
}

.form-fields > .alert::before {
  right: -50px;
}

.submission-error,
.submission-success {
  position: absolute;
  top: calc(50% - 224px);
  z-index: 15;
  width: 100%;
  .container {
    width: 335px;
    height: 448px;
    margin: 0 auto;
    background-color: white;
    padding-top: 42px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
  h1 {
    font-weight: 900;
    font-size: 34px;
    text-align: center;
    margin-top: 25px;
    color: #232323;
  }
  p {
    line-height: 22px;
    font-size: 17px;
    color: #232323;
    opacity: 0.6;
    text-align: center;
    margin-top: 11px;
  }
  button {
    display: block;
    width: 297px;
    height: 44px;
    margin: 63px auto 0;
    color: white;
    background-color: #003cd5;
    line-height: 44px;
    font-size: 17px;
    font-weight: bold;
    border: none;
    border-radius: 7px;
    cursor: pointer;
  }
  button.cancel {
    margin-top: 10px;
    background-color: transparent;
    color: #232323;
    opacity: 0.4;
  }
}

.submission-success {
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 170px;
  background: rgba(0, 0, 0, 0.35);
  .container {
    position: relative;
    border-radius: 3px;
    padding-top: 110px;
    button {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 30px;
      height: 30px;
      margin: 0;
      background-color: white;
      border: none;
    }
  }
  h1 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
  }
}

@media screen and (min-width: 830px) {
  .brand-footer-error {
    justify-content: space-between;
    bottom: 29px;
    p {
      padding: 0 44px;
      margin: 0 0px 15px;
    }
  }
  .brand-form-footer {
    display: none;
  }
  .group-name-org {
    display: flex;
    justify-content: space-between;
    input {
      width: 47.75%;
    }
    .error {
      margin-bottom: 30px;
    }
    .alert.top {
      position: absolute;
      top: 38px;
    }
    .alert::before {
      position: absolute;
      right: -13px;
    }
  }
  .submission-error,
  .submission-success {
    .container {
      width: 386px;
    }
  }
}

.question-group {
  margin-bottom: 30px;
}

.question-groups {
  margin: 0 auto 80px auto;
  padding: 0 20px;
  text-align: left;
  max-width: 600px;

  h3 {
    margin-bottom: 10px;
  }
}

.terms {
  text-align: left;
  padding: 0 20px;
  max-width: 800px;
  margin: 0 auto 80px auto;
}
.homepage {
  .page {
    height: calc(100vh - 40px);
    width: 100vw;
  }

  .page-1 {
    height: 100vh;
    @media (max-width: $breakpoint) {
      height: auto;
    }
    z-index: 1;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    position: relative;
    .page-1-content-box-mobile {
      @media (min-width: $breakpoint) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .mobile-app-icon {
        width: 86px;
        height: 86px;
        margin-top: 30px;
      }
      .league-life-mobile-content-box {
        padding: 20px;
        background-color: #101b35;
        border-radius: 10px;
        width: 90%;
        .mobile-description {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0px;
          text-align: center;
          max-width: 200px;
        }
        .league-life-mobile-content-box-buttons {
          margin: 30px 0;
          display: flex;
          gap: 20px;
          justify-content: center;
          align-items: center;
          .app-store-btn,
          .check-status-btn {
            width: 108px;
            height: 35px;
            margin: 0;
          }
          .check-status-btn {
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: center;
            color: #212020;
            background-color: #fff;
            border-radius: 5px;
          }
        }

        .bottom-text {
          font-size: 10px;
          font-weight: 400;
          line-height: 13px;
          letter-spacing: 1px;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
        }
      }
      .mobile-basketball-image {
        width: 100%;
        margin-bottom: 60px;
      }
    }
    .page-1-content-box-desktop {
      @media (max-width: $breakpoint) {
        display: none;
      }
      position: absolute;
      top: 0;
      bottom: 0;
      margin-bottom: auto;
      margin-top: auto;
      left: 10%;
      @media (max-width: 1000px) {
        left: 2.5%;
      }

      width: 500px;
      height: 250px;
      display: flex;
      flex-direction: column;
      .page-1-content-box-desktop-top {
        display: flex;
        img {
          width: 135px;
          height: 135px;
        }
        h1 {
          margin: 0;
        }
        .span-wrapped {
          text-align: left;
          margin-top: 12.5px;
          display: flex;
          flex-direction: column;
          font-size: 18px;
        }

        .page-1-content-box-desktop-top-text {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          margin-top: 15px;
        }
      }
      .page-1-content-box-desktop-bottom {
        display: flex;
        margin-top: 30px;
        gap: 30px;
        .app-store-btn {
          width: 154px;
          height: 51px;
        }
        .check-status-btn {
          width: 154px;
          height: 51px;
          background-color: #fff;
          border-radius: 8px;
          color: #212020;
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .page-2 {
    z-index: 1;
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;

    .video-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }

    @media (max-width: $breakpoint) {
      height: 400px;
    }

    .testimonials {
      position: absolute;
      right: 100px;
      width: 478px;
      height: 471px;
      border-radius: 20px;
      bottom: 25%;
      top: 25%;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: #0d1021;
      padding: 5px;

      @media (max-width: $breakpoint) {
        width: 300px;
        height: 200px;
        right: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
      }

      .testimonial-label {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 1px;
        text-align: center;

        @media (max-width: $breakpoint) {
          margin-top: 0;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0.40536800026893616px;
          text-align: center;
        }
      }

      .testimonial-frame {
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: $breakpoint) {
          height: 125px;
        }
        h1 {
          font-size: 52px;
          font-weight: 700;
          line-height: 57px;
          letter-spacing: 1px;
          text-align: center;
          margin: 0;
          @media (max-width: $breakpoint) {
            font-size: 24px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0px;
            text-align: center;
          }
        }
        h2 {
          font-size: 38px;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: 1px;
          text-align: center;
          margin: 0;
          @media (max-width: $breakpoint) {
            font-size: 20px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0px;
            text-align: center;
          }
        }
        .testimonial-name {
          display: flex;
          flex-direction: column;
          .testimonial-name-top {
            font-size: 32px;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: 1px;
            text-align: center;
            @media (max-width: $breakpoint) {
              font-size: 18px;
              font-weight: 700;
              line-height: 21px;
              letter-spacing: 0.40536800026893616px;
              text-align: center;
            }
          }
          .testimonial-name-bottom {
            font-size: 24px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 1px;
            text-align: center;
            @media (max-width: $breakpoint) {
              font-family: Roboto;
              font-size: 14px;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: 0.40536800026893616px;
              text-align: center;
            }
          }
        }
      }
      .dots {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-bottom: 30px;
        .dot {
          width: 14px;
          height: 14px;
          border: 1px solid #0d1021;
          border-radius: 100%;
        }
      }
    }
  }
  .page-3 {
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    h2 {
      font-size: 30px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      @media (max-width: $breakpoint) {
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
    .nba-teams-container {
      background-color: white;
      width: fit-content;
      padding: 10px 20px;
      border-radius: 8px;
    }
  }
  .page-4 {
    height: 140vh;
    z-index: 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    @media (max-width: $breakpoint) {
      align-items: center;
      flex-direction: column;
      height: auto;
    }
    .slider-mobile {
      @media (min-width: $breakpoint) {
        display: none;
      }
      width: 100%;
      margin: 30px 0;
    }

    .page-4-header {
      margin-top: 64px;
      max-width: 500px;
      @media (max-width: 1000px) {
        margin-top: 32px;
      }
      @media (max-width: $breakpoint) {
        margin-top: 64px;
        padding: 5%;
      }
    }

    .card {
      width: 200px;
      position: absolute;
      @media (max-width: $breakpoint) {
        display: none;
      }
    }
    @keyframes scaleAnimation {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }

    .card-1 {
      animation: scaleAnimation 3s infinite;
      bottom: 65%;
      left: 12%;
    }

    .card-2 {
      animation: scaleAnimation 4s infinite;
      bottom: 42.5%;
      left: 8%;
    }

    .card-3 {
      animation: scaleAnimation 5s infinite;
      bottom: 20%;
      left: 13%;
    }

    .card-4 {
      animation: scaleAnimation 6s infinite;
      bottom: 65%;
      right: 8%;
    }

    .card-5 {
      animation: scaleAnimation 7s infinite;
      bottom: 42.5%;
      right: 12%;
    }

    .card-6 {
      animation: scaleAnimation 8s infinite;
      bottom: 20%;
      right: 8%;
    }

    .logo-gif {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 400px;
      height: 400px;
      @media (max-width: $breakpoint) {
        position: relative;
        margin-top: 60px;
        width: 225px;
        height: 225px;
      }
    }
  }
  .page-5 {
    z-index: 1;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      margin-top: 50px;
    }
    span {
      width: 450px;
      @media (max-width: $breakpoint) {
        width: 70%;
      }
    }
    img {
      margin: 30px 0 60px;
      max-width: 100%;
    }
  }
  .page-6 {
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    position: relative;
    justify-content: space-around;
    gap: 50px;
    overflow: hidden;

    @media (max-width: $breakpoint) {
      flex-direction: column;
      height: auto;
      padding: 40px 0;
    }

    .book-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      gap: 30px;
      max-width: 420px;

      @media (max-width: $breakpoint) {
        align-items: center;
        width: 100%;
        max-width: 100%;
      }

      h1 {
        font-size: 52px;
        font-weight: 700;
        line-height: 57px;
        letter-spacing: 1px;
        text-align: left;
        margin: 0;

        @media (max-width: $breakpoint) {
          font-size: 30px;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: 1px;
          text-align: center;
          width: 60%;
        }
      }
      span {
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: left;
        @media (max-width: $breakpoint) {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
          max-width: 70%;
        }
      }

      img {
        height: auto;
        width: 100px;
        z-index: 998;
      }
    }

    .book-image {
      width: 500px;
      position: relative;

      @media (max-width: $breakpoint) {
        margin-top: 320px;
        width: 100%;
      }

      .phone {
        z-index: 999;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .radiant {
        z-index: 888;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
      }
    }
  }
  .page-7 {
    z-index: 1;
    // box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint) {
      display: block;
    }

    .page-7-content {
      width: 90%;
      display: flex;
      gap: 20px;
      justify-content: center;

      @media (max-width: $breakpoint) {
        gap: 0;
        width: 100%;
        flex-direction: column-reverse;
      }

      .page-7-images {
        display: flex;
        gap: 20px;
        overflow: hidden;

        img {
          max-height: 410px;
          border-radius: 10px;
        }

        @media (max-width: $breakpoint) {
          padding-left: 20px;
        }
      }

      .page-7-text {
        display: flex;
        flex-direction: column;
        width: 30%;

        @media (max-width: $breakpoint) {
          margin-top: 80px;
          width: 100%;
          padding: 0 10%;
        }

        h1 {
          font-size: 52px;
          font-weight: 700;
          line-height: 58px;
          letter-spacing: 1px;
          text-align: left;

          @media (max-width: $breakpoint) {
            font-size: 30px;
            font-weight: 700;
            line-height: 34px;
            letter-spacing: 1px;
            text-align: center;
          }
        }

        span {
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0px;
          text-align: left;

          @media (max-width: $breakpoint) {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            margin-bottom: 60px;
          }
        }
      }
    }
  }
  .page-8 {
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    height: 645px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $breakpoint) {
      height: 500px;
      background-color: red;
    }
    h1 {
      margin-top: 120px;
      font-size: 52px;
      font-weight: 700;
      line-height: 67px;
      letter-spacing: 1px;

      @media (max-width: $breakpoint) {
        font-size: 30px;
        font-weight: 700;
        line-height: 39px;
        letter-spacing: 0.5479166507720947px;
      }
    }
    .check-status-button {
      width: 182px;
      height: 43px;
      border-radius: 6px;
      background-color: white;
      color: black;
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bottom-basketball {
      width: 645px;
      height: 645px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      bottom: -300px;
      @media (max-width: $breakpoint) {
        height: 400px;
        width: 400px;
        bottom: -150px;
      }
    }
  }
}

/* Marquee styles */
.marquee {
  font-family: "dunbarlowbold";
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.5447494387626648px;

  z-index: 1000;
  --gap: 1rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  background-color: #003cd5;
  color: black;
  align-items: center;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll 10s linear infinite;
    li {
      list-style-type: none;
    }
  }
}
.ll-marquee {
  font-family: "dunbarlowbold";
  z-index: 1000;
  --gap: 1rem;
  height: 90px;
  background-color: #0d34bf;
  color: black;
  align-items: center;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  .ll-marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll 10s linear infinite;
    li {
      list-style-type: none;
      text-transform: uppercase;
    }
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

/* Pause animation when reduced-motion is set */
@media (prefers-reduced-motion: reduce) {
  .marquee__content {
    animation-play-state: paused !important;
  }
}
.ReactModal__Overlay {
  z-index: 9999;
}
.close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  margin: 0;
  cursor: pointer;
}
.modal-message {
  display: flex;
  flex-direction: column;
  a {
    margin-top: 15px;
    text-transform: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-top: 60px;
    }
  }
  .check-status-btn {
    margin-top: 20px;
    width: 154px;
    height: 51px;
    background-color: #fff;
    border-radius: 8px;
    color: #212020;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.email-modal-input {
  padding: 30px;
  border-radius: 5px;
  font-size: 18px;
  color: #212020;
  text-transform: none !important;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.email-modal-btn {
  width: 154px;
  height: 51px;
  background-color: #fff;
  border-radius: 8px;
  color: #212020;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.email-modal-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
}
