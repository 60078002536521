@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900);
$breakpoint: 750px;


body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'dunbarlowbold';
  src: url('../fonts/dunbarlow-bold-webfont.woff2') format('woff2'),
       url('../fonts/dunbarlow-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
h1,h2,h3,h4,h5,h6{
  font-family: 'dunbarlowbold';
  text-transform: uppercase;
}

h1 {
  font-size: 52px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 1px;
  @media (max-width: $breakpoint) {
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 1px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
